import React from "react";
import styled from 'styled-components'
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { ListViewItem, BackButton } from '../../components/common';

const PageWrapper = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  min-height:100vh;
`

const StoryListWrapper = styled.div`
  padding: 5% 5% 5% 5%;
`

const VoiceProfileWrapper = styled.div`
  padding: 0% 5% 0% 5%;
  display:flex;
  position:relative;
  z-index:10;
 
`

const VoiceName = styled.h1`
  margin: 10px 0 0 20px; 
`

const VoiceAvatarWrapper = styled.div`
  width:33%;
  display:flex;
  justify-content:center;
`

const AvatarImageWrapper = styled.div`
  border-radius:50%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border:5px solid ${props => props.theme.secondaryColor};
  width: 110px;
  height: 110px;
  overflow: hidden;
  margin-top:-55px;
  display: grid;
  place-items: center;
  background-color: ${props => props.theme.secondaryColor};
  @media (max-width: 374px) {
    width: 90px;
    height: 90px;
    margin-top:-45px;
  }
`

const BannerWrapper = styled.div`
  height:150px;
  background-color:white;
  opacity:0.1;
  overflow:hidden;
  position:relative;
  z-index:5;
`

const AvatarPlaceholder = styled.h2`
  text-align:center;
  font-size:45px;
  margin:0px;
  color:white;
`



const StoryVoiceTemplate = (props) => {
  const lang = props.pageContext.lang;
  return(
    <PageWrapper>
      <BackButton absolute marginTop marginLeft/>
      <BannerWrapper>
        {props.data.allStory.edges.length > 0 &&
          <GatsbyImage image={props.data.allStory.edges[0].node.localImage.coverImage.gatsbyImageData} alt={`Voice avatar`}/>
        }
      </BannerWrapper>
      <VoiceProfileWrapper>
        <VoiceAvatarWrapper>
          <AvatarImageWrapper>
            {props.data.voice.localImage != null ?
              <GatsbyImage image={props.data.voice.localImage.childImageSharp.gatsbyImageData} alt={`Voice avatar`}/>
              :
              <AvatarPlaceholder>{props.data.voice.name.charAt(0)}</AvatarPlaceholder>
            }
          </AvatarImageWrapper>
        </VoiceAvatarWrapper>
        <VoiceName>{props.data.voice.name}</VoiceName>
      </VoiceProfileWrapper>
      <StoryListWrapper>
        {props.data.allStory.edges.filter((story) => story.node[lang].published).map(edge =>(
          <ListViewItem
            id={edge.node.id}
            slug={edge.node[lang].slug}
            title={edge.node[lang].title}
            sampleAudioRef={edge.node[lang].sampleAudioRef}
            storyCover={edge.node.localImage.coverImage.gatsbyImageData}
            thumbnail={edge.node.localImage.thumbnail.gatsbyImageData}
            duration={edge.node[lang].duration}
            voices={edge.node[lang].voices}
            mainFantasy={edge.node.mainFantasy[lang].fantasy}
            spiciness={edge.node.spiciness}
            premiumContent={edge.node[lang].premiumContent}
            audiodesiresOriginal={edge.node.audiodesiresOriginal}
            key={edge.node.id}
          />
        ))}
      </StoryListWrapper>
    </PageWrapper>
  )
};
// this way will inject query into props.data
export const query = graphql`
  query VoiceAppQuery($voiceId: String!, $storyIds: [String!], $isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!, $hasPic: Boolean!){
    voice(id: {eq: $voiceId}) {
      name
      localImage  @include(if: $hasPic){
       childImageSharp {
          gatsbyImageData( placeholder: BLURRED,width: 120, height: 120, layout: CONSTRAINED)
        }
      }
    }
    allStory(filter: { id: {in: $storyIds}}) {
      edges {
        node {
          ...sliderItemInfo
        }
      }
    }
    dummyToUseVarInFragment: allStory(filter: {id: {eq: "nevertrue"}}){
      edges {
        node{
          id @include(if: $isEs)
          id @include(if: $isEn)
          id @include(if: $isDe)
        }
      }
    }
  }
`;  

export default StoryVoiceTemplate;
